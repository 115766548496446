import React, { Component } from "react";
import Icon from '@material-ui/core/Icon';
import Head from "next/head";
import { Link, Router } from "routes";
import NProgress from "components/NProgress";
import Axios from "/plugins/axios";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SeoMeta from '/components/seoMeta';
import CovidGlobalStats from '/components/covidGlobalStats';
import { findCategory, covidSections, duwunCoverLogo } from '/utils/common';

// const covidSections = {
//     covid19 : "covid-19",
//     covid19Local : "covid-19-local-news",
//     covid19Global : "covid-19-global-news",
//     covid19Stats : "covid-19-stats",
//     covid19Map : "covid-19-map",
//     covid19Charts : "charts",
//     covid19MOHS : "mohs",
//     covid19JHU : "jhu",
//   }

export default class customPage extends Component {
    constructor(props){
        super(props);
        this.activeCategory = this.activeCategory.bind(this);
    }
activeCategory = () => {
    const { allCategories, url, categoryObj, catQuery } = this.props.props;
    const categorySegment = url.query.category;
    const isBrandSite = url.asPath.includes('/sites/');
    const isBrandCategory = false;
    let findCurrentCategory = allCategories && allCategories.length > 0 && findCategory(allCategories, catQuery)
    let parentCatObj = findCurrentCategory && findCurrentCategory.currentCategoryObj; 
     let subCategoryObj = findCurrentCategory && findCurrentCategory.currentSubCategoryObj
    if(!parentCatObj && !subCategoryObj && isBrandSite) {
        isBrandCategory = true;
        parentCatObj = categoryObj; // Brand Categories are excluded in api/categories and categoriesStore
    }
    let categoryType = subCategoryObj ? `${subCategoryObj.type}` : `${parentCatObj && parentCatObj.type}`;
    let categoryName = subCategoryObj ? `${subCategoryObj.name}` : `${parentCatObj && parentCatObj.name}`;
    let categoryColor = subCategoryObj ? `${subCategoryObj.color}` : `${parentCatObj && parentCatObj.color}`;
    let categorySlug = subCategoryObj ? `${subCategoryObj.slug}` : `${parentCatObj && parentCatObj.slug}`;
    let categoryCover = subCategoryObj ? (subCategoryObj.cover && `${subCategoryObj.cover.original}`) : (parentCatObj && parentCatObj.cover && `${parentCatObj.cover.original}`);
    return ({
        categoryType,
        categoryName,
        categoryColor,
        categorySlug,
        categoryCover,
        isBrandCategory
    })
}
    render() {
        const { ssrDone } = this.props;
        const {categoryObj, metaTag, url } = this.props.props;
        let activeCategory = this.activeCategory();
        const categoryName = activeCategory.categoryName
        const categoryType = activeCategory.categoryType
        const categoryColor = activeCategory.categoryColor
        const categorySlug = url.query.id ? url.query.id: url.query.catQuery ? url.query.catQuery: activeCategory.categorySlug
        const categoryCover = activeCategory.categoryCover
        const isBrandCategory = activeCategory.isBrandCategory
        const defaultImage = duwunCoverLogo;
        let brandCategoryCover = isBrandCategory ? (categoryObj && categoryObj.cover && `${categoryObj.cover.social}`) : defaultImage;
        let ogImage = isBrandCategory ? brandCategoryCover : categoryCover || defaultImage;
    
        const viewAllCOVIDStats = (subSection, label) => (<Grid item xs={12}>
            <Link route={"articlePost"} params={{ id: subSection }}>
              <a className="anchor-link">
                <div className="read_all_articles">
                  <Typography
                    variant="body1"
                    align="right"
                    style={{
                    color: categoryColor,
                    cursor: 'pointer',
                    textTransform: 'capitalize',
                    marginBottom: 26
                    }}
                  >
                    {label}
                    <Icon style={{ paddingTop: 6 }}>
                    keyboard_arrow_right
                    </Icon>
                  </Typography>
                </div>
              </a>
            </Link>
          </Grid>)
        return (
            <div>
            <Head>
              <title>{metaTag && metaTag.title} | Duwun</title>
            </Head>
            <SeoMeta
                url={`${process.env.BACKEND_URL}${url.asPath}`}
                type="article"
                title={metaTag && metaTag.title}
                description={metaTag && metaTag.description}
                image={ogImage}
                themeColor={categoryColor}
                section={metaTag && metaTag.section ? metaTag.section : categoryName}
                keywords={metaTag && metaTag.keywords ? metaTag.keywords : categoryName}
              />        
            <NProgress />
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
              <Grid item xs={12} sm={10} md={8}>
              <h1 style={{textAlign : 'center', color : categoryColor, margin: '20px 0px -15px', fontSize: 23 }}>{metaTag && metaTag.title}</h1>
              {/* ********************** Start Stats *********************** */}
              {
                categorySlug == covidSections.covid19Stats &&
                <React.Fragment>
                <h3 style={{textAlign : 'center', color : categoryColor}}>Statistics</h3>
                <CovidGlobalStats ssrDone={ssrDone} type={"geo"} />
                </React.Fragment>
              }
               {
                categorySlug == covidSections.newCovid19Stats &&
                <React.Fragment>
                <h3 style={{textAlign : 'center', color : categoryColor}}>Statistics</h3>
                <CovidGlobalStats ssrDone={ssrDone} type={"newStats"} />
                </React.Fragment>
              }
              {/* ********************** End Stats *********************** */}
              {/* ********************** Start Map *********************** */}
              {
                categorySlug == covidSections.covid19Map &&
                <React.Fragment>
                <h4 style={{textAlign : 'center', color : categoryColor}}>Confirmed Cases In Myanmar (First Wave Only)</h4>
                <CovidGlobalStats ssrDone={ssrDone} type={"map"} />
                <br/>
                <h4 style={{textAlign : 'center', color : categoryColor}}>Mindmap of Confirmed Cases (First Wave Only)</h4>
                <CovidGlobalStats ssrDone={ssrDone} type={"mindmap"} />
                <br/>
                <h4 style={{textAlign : 'center', color : categoryColor}}>For Second Wave data, click the link below</h4>
                {viewAllCOVIDStats(covidSections.newCovid19MOHS, 'View MOHS Surveillance Dashboard')}
                </React.Fragment>
              }
              {/* ********************** End Map *********************** */}
              {/* ********************** Start Charts *********************** */}
              {
                categorySlug == covidSections.covid19Charts &&
                <React.Fragment>
    
                <h3 style={{textAlign : 'center', color : categoryColor}}>Charts for Global and ASEAN</h3>
                <CovidGlobalStats key={2} ssrDone={ssrDone} type={"allTop7"} />
                {/* {
                isMobile ?
                <CovidGlobalStats key={2} ssrDone={ssrDone} type={"allTop7"} />
                :
                <>
                <CovidGlobalStats key={2} ssrDone={ssrDone} type={"all"} />
                <br/>
                <h3 style={{textAlign : 'center', color : categoryColor}}>Top 6 countries</h3>
                <CovidGlobalStats key={3} ssrDone={ssrDone} type={"US"} />
                <CovidGlobalStats key={4} ssrDone={ssrDone} type={"Italy"} />
                <CovidGlobalStats key={5} ssrDone={ssrDone} type={"Spain"} />
                <CovidGlobalStats key={6} ssrDone={ssrDone} type={"Germany"} />
                <CovidGlobalStats key={7} ssrDone={ssrDone} type={"France"} />
                <CovidGlobalStats key={8} ssrDone={ssrDone} type={"China"} />
                </>
                } */}
    
                </React.Fragment>
              }
               {
                categorySlug == covidSections.newCovid19Charts &&
                <React.Fragment>
                <h3 style={{textAlign : 'center', color : categoryColor}}>Charts for Global and ASEAN</h3>
                <CovidGlobalStats key={2} ssrDone={ssrDone} type={"newCharts"} />
                </React.Fragment>
              }
              {/* ********************** End Charts *********************** */}
              {/* ********************** Start MOHS *********************** */}
              {
                categorySlug == covidSections.covid19MOHS &&
                <React.Fragment>
                <h3 style={{textAlign : 'center', color : categoryColor}}>Myanmar Statistics (MOHS)</h3>
                <CovidGlobalStats key={0} ssrDone={ssrDone} type={"mohs"} />
                <br/>
                {viewAllCOVIDStats(covidSections.newCovid19Stats, 'View More Detailed COVID-19 Stats')}
                </React.Fragment>
              }
               {
                categorySlug == covidSections.newCovid19MOHS &&
                <React.Fragment>
                <h3 style={{textAlign : 'center', color : categoryColor}}>Myanmar Statistics (MOHS)</h3>
                <CovidGlobalStats key={0} ssrDone={ssrDone} type={"newMohs"} />
                <br/>
                {viewAllCOVIDStats(covidSections.newCovid19Stats, 'View More Detailed COVID-19 Stats')}
                </React.Fragment>
              }
              {/* ********************** end MOHS *********************** */}
              {/* ********************** Start MOHS *********************** */}
              {
                categorySlug == covidSections.covid19JHU  &&
                <React.Fragment>
                <h3 style={{textAlign : 'center', color : categoryColor}}>World Statistics (JHU)</h3>
                <CovidGlobalStats key={1} ssrDone={ssrDone} type={"jhu"} />
                <br/>
                {viewAllCOVIDStats(covidSections.newCovid19Stats, 'View Local COVID-19 Stats')}
                </React.Fragment>
              }
               {
                categorySlug == covidSections.newCovid19JHU &&
                <React.Fragment>
                <h3 style={{textAlign : 'center', color : categoryColor}}>World Statistics (JHU)</h3>
                <CovidGlobalStats key={1} ssrDone={ssrDone} type={"newJhu"} />
                <br/>
                {viewAllCOVIDStats(covidSections.newCovid19Stats, 'View Local COVID-19 Stats')}
                </React.Fragment>
              }
                {/* ********************** end JHU *********************** */}
              </Grid>
            </Grid>
            </div>
          );
    }
}


