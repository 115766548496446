// https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
// https://medium.com/better-programming/4-ways-of-adding-external-js-files-in-reactjs-823f85de3668
// https://www.newline.co/fullstack-react/articles/Declaratively_loading_JS_libraries/
import { useEffect } from 'react';
const importScript = resourceUrl=> {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = true;
    document.head.appendChild(script);
return () => {
      document.head.removeChild(script);
    }
  }, [resourceUrl]);
};
export default importScript;

export const CustomScript = (props) => {
    let {src} = props;
    importScript(src);
    return (<></>);
};