import React, { Component } from "react";
import Hidden from '@material-ui/core/Hidden';
import { isMobile } from "react-device-detect";
import Lazyload from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

// export const initCovidWidgets = () => {
//     // console.log("initCovidWidgets");
//     setTimeout(function(){
//         let covidWidgets = document.getElementsByClassName('covid-widget');
//         for ( let i = 0; i < covidWidgets.length; i += 1 ){
//             covidWidgets[i].src = covidWidgets[i].dataset.src;
//             covidWidgets[i].style.height = covidWidgets[i].dataset.height+"px";
//             // console.log("initCovidWidgets:"+covidWidgets[i].dataset.src);
//         }
//     }, 3000);
// }

class CovidGlobalStats extends Component {

    constructor(props){
        super(props);
        this.desktopIframeCovid = this.desktopIframeCovid.bind(this);
        this.mobileIframeCovid = this.mobileIframeCovid.bind(this);
    }

    desktopIframeCovid(type, width) {
        let src = "";
        let height = 320;
        if(type == "map"){
            return (
            <div style={{ marginTop: 4, textAlign: 'center' }}>
                <iframe src="https://www.google.com/maps/d/embed?mid=1zmWrbNEIjmaPvUnMx_7-33ZqP-uh88z-" 
                width="100%" height="600" style={{ border: '0' }}></iframe>
            </div>
            );
        }    
        else if (type == "mohs" || type == 'newMohs') {
            src = width && width <= 330 ? 
            "https://doph.maps.arcgis.com/apps/opsdashboard/index.html#/ba4c64db4f1946f2af8fa929762bf8db" :
            "https://doph.maps.arcgis.com/apps/opsdashboard/index.html#/f8fb4ccc3d2d42c7ab0590dbb3fc26b8";
            return (
            <div style={{ marginTop: 4, textAlign: 'center' }}>
                <iframe src={src} width={ width || 860 } height={520} style={{ border: '0' }}></iframe>
            </div>
            );
        }       
        else if (type == "jhu" || type == "newJhu") {
            return (
            <div style={{ marginTop: 4, textAlign: 'center' }}>
                <iframe src="https://doph.maps.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6" 
                width={ width || 860 } height={520} style={{ border: '0' }}></iframe>
            </div>
            );
        }  
        else if (type == "mindmap") {
            src = "https://duwun-data.sgp1.cdn.digitaloceanspaces.com/DFP/image/covid-19-mm-mindmap.html?cb="+Date.now();
            // src = "https://app.diagrams.net?lightbox=1&highlight=0000ff&layers=1&nav=1&title=COVID-19-MM-Mindmap.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fa%2Fduwun.com.mm%2Fuc%3Fid%3D1TM1lkdYTAjCVxRzFqS2DZzjNDiRjRa5d%26export%3Ddownload";
            return (
            <div style={{ marginTop: 4, textAlign: 'center', position: 'relative' }}>
                <a href={src} target={"_blank"} style={{ display:'inline-block', position:'absolute', top: '0.5rem', right: '1.5rem',  
                    width:'3rem', height:'3rem', fontSize:'2rem', textDecoration: 'none', color: '#005bac',lineHeight: '3rem'}}>
                    <FontAwesomeIcon icon={faExpand} />
                </a>
                <iframe src={src} width={ width || 860 } height={890} style={{ border: '0' }}></iframe>
            </div>
            );
        }      
        width = 860;                
        switch (type) 
        {
            case "geo":
                height = 1120;
                src = "https://datastudio.google.com/embed/reporting/1GWUZMRAW3eVyzx12ZUobgghGrac2fn9Z/page/UKALB";
                break;
            case "newStats":
                height = 1120;
                src = "https://datastudio.google.com/embed/reporting/1GWUZMRAW3eVyzx12ZUobgghGrac2fn9Z/page/UKALB";
                break;
            case "all": 
                height = 440;
                src = "https://datastudio.google.com/embed/reporting/131j6D3PUCfqH7LCxrk1bRnFTKFd8XJDQ/page/pqpJB";
                break;
            case "allTop7" :
                height = 3690;
                src = "https://datastudio.google.com/embed/reporting/1KtEkhWyPwM1bgS8EXbu-kCDVjP853HO9/page/pqpJB";
                break; 
            case "newCharts" :
                height = 3690;
                src = "https://datastudio.google.com/embed/reporting/1KtEkhWyPwM1bgS8EXbu-kCDVjP853HO9/page/pqpJB";
                break; 
            case "asean":
                width = 560;
                height = 3290;
                src = "https://datastudio.google.com/embed/reporting/1KtEkhWyPwM1bgS8EXbu-kCDVjP853HO9/page/mx9MB";
                break;                               
            case "China": 
                src = "https://datastudio.google.com/embed/reporting/1rq30SKbikTreR0itERe-FM2Xw8tpjD1Y/page/pqpJB";
                break;            
            case "Italy": 
                src = "https://datastudio.google.com/embed/reporting/1V-guNB21xUASRnqUgqro_dp4G6KCHF5b/page/pqpJB";
                break;
            case "US": 
                src = "https://datastudio.google.com/embed/reporting/1Zuu--51sGEkkRmDynF2tQA_v89AcakdC/page/pqpJB";
                break; 
            case "Spain": 
                src = "https://datastudio.google.com/embed/reporting/1kKuatFfTKLM5fgBgjLtXGTardEifKHUC/page/pqpJB";
                break;   
            case "UK": 
                src = "https://datastudio.google.com/embed/reporting/1eYhDAhiXTvvWmYH8-T9ten3DwCGprKHB/page/pqpJB";
                break; 
            case "Germany": 
                src = "https://datastudio.google.com/embed/reporting/1pYrmfSdPD6Hru_CUdhWilCq2_eoKqWOv/page/pqpJB";
                break;                 
            case "France": 
                src = "https://datastudio.google.com/embed/reporting/1ato1iEM_WzYe-hZmuYy9bxtsEvQiC-r4/page/pqpJB";
                break;                                                  
            default: break;
        }
        return (
            <div style={{ marginTop: 14, textAlign: 'center' }} dangerouslySetInnerHTML=
            {{__html: '<iframe class="covid-widget" width="' + width + '" data-height="0" height="' + height + '" data-src="about:blank" src="' + src + '" frameborder="0" style="border:0" allowfullscreen></iframe>'}}
            />
        );
    }

    mobileIframeCovid(type) {
        let src = "";
        let height = 370;
        if (type == "map") {
            return (
            <div style={{ marginTop: 4, textAlign: 'center' }}>
                <iframe src="https://www.google.com/maps/d/embed?mid=1zmWrbNEIjmaPvUnMx_7-33ZqP-uh88z-" 
                width="330" height="600" style={{ border: '0' }}></iframe>
            </div>
            );
        }
        else if (type == "mohs" || type == 'newMohs') {
            return (
            <div style={{ marginTop: 4, textAlign: 'center' }}>
                <iframe src="https://doph.maps.arcgis.com/apps/opsdashboard/index.html#/ba4c64db4f1946f2af8fa929762bf8db" 
                width="330" height="600" style={{ border: '0' }}></iframe>
            </div>
            );
        }
        else if (type == "jhu" || type == "newJhu") {
            return (
            <div style={{ marginTop: 4, textAlign: 'center' }}>
                <iframe src="https://doph.maps.arcgis.com/apps/opsdashboard/index.html#/85320e2ea5424dfaaa75ae62e5c06e61" 
                width="330" height="600" style={{ border: '0' }}></iframe>
            </div>
            );
        }  
        else if (type == "mindmap") {
            src = "https://duwun-data.sgp1.cdn.digitaloceanspaces.com/DFP/image/covid-19-mm-mindmap.html?cb="+Date.now();
            // src = "https://app.diagrams.net?lightbox=1&highlight=0000ff&layers=1&nav=1&title=COVID-19-MM-Mindmap.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fa%2Fduwun.com.mm%2Fuc%3Fid%3D1TM1lkdYTAjCVxRzFqS2DZzjNDiRjRa5d%26export%3Ddownload";
            return (
            <div style={{ marginTop: 4, textAlign: 'center', position: 'relative' }}>
                <a href={src} target={"_blank"} style={{ display:'inline-block', position:'absolute', top: '0.5rem', right: '2rem',  
                    width:'3rem', height:'3rem', fontSize:'2rem', textDecoration: 'none', color: '#005bac',lineHeight: '3rem'}}>
                    <FontAwesomeIcon icon={faExpand} />
                </a>
                <iframe src={src} width="330" height="360" style={{ border: '0' }}></iframe>
            </div>
            );
        } 
        switch (type) 
        {
            case "geo":
                height = 440;
                src = "https://datastudio.google.com/embed/reporting/1GWUZMRAW3eVyzx12ZUobgghGrac2fn9Z/page/UKALB";
                break;   
            case "newStats":
                height = 440;
                src = "https://datastudio.google.com/embed/reporting/1GWUZMRAW3eVyzx12ZUobgghGrac2fn9Z/page/UKALB";
                break;            
            case "all": 
                height = 600;
                // src = "https://datastudio.google.com/embed/reporting/1sVsH1lzze49OBpys25ghXXbdr2wdbsca/page/pqpJB";
                src = "https://datastudio.google.com/embed/reporting/1pOHBXJQGa2dj7mgsw9rF-W2eoGsEfvKF/page/pqpJB";
                break;
            case "allTop7":
                height = 1990;
                src = "https://datastudio.google.com/embed/reporting/1KtEkhWyPwM1bgS8EXbu-kCDVjP853HO9/page/pqpJB";
                break;
            case "newCharts":
                height = 1990;
                src = "https://datastudio.google.com/embed/reporting/1KtEkhWyPwM1bgS8EXbu-kCDVjP853HO9/page/pqpJB";
                break;
            case "asean":
                height = 1940;
                src = "https://datastudio.google.com/embed/reporting/1KtEkhWyPwM1bgS8EXbu-kCDVjP853HO9/page/mx9MB";
                break;
            case "China": 
                src = "https://datastudio.google.com/embed/reporting/1B3_rBstIVqqeo-ZURpm7PJLiJxQYCgQ-/page/pqpJB";
                break;               
            case "Italy": 
                src = "https://datastudio.google.com/embed/reporting/1OQbGpVmNpjE8VB47YusgL3xocRu6a5z3/page/pqpJB";
                break;
            case "US": 
                src = "https://datastudio.google.com/embed/reporting/19SwTmDVFhi3PYFUWEnYtwc4W-pI7HvyL/page/pqpJB";
                break;   
            case "Spain": 
                src = "https://datastudio.google.com/embed/reporting/1H9AhtYw0ErQbmLZB10dfi4Ux5fRAReqI/page/pqpJB";
                break;   
            case "UK": 
                src = "https://datastudio.google.com/embed/reporting/1NLG9YVWzzCeIBYtk5Zdfr5yfUAHjoHal/page/pqpJB";
                break;  
            case "Germany": 
                src = "https://datastudio.google.com/embed/reporting/1wcVccSQ_IU6ANclAj22PhtB9b5Gaz8_N/page/pqpJB";
                break;                
            case "France": 
                src = "https://datastudio.google.com/embed/reporting/1A921597YKXzgaMy5pa6AZhe7V1I8BCGi/page/pqpJB";
                break;                                               
            default: break;
        }
        return (
            <div style={{ marginTop: 14, textAlign: 'center' }} dangerouslySetInnerHTML=
            {{__html: '<iframe class="covid-widget" width="330" data-height="0" height="' + height + '" data-src="about:blank" src="' + src + '" frameborder="0" style="border:0" allowfullscreen></iframe>'}}
            />
        );
    }

  render() {
    const { ssrDone, type, width } = this.props;
    let _type = /_/.test(type) ? type.split("_")[1] : type;
    if(!_type) return null;
    switch (_type) 
    {
        case "United-States": 
            _type = "US";
            break;
        case "United-Kingdom-UK": 
            _type = "UK";
            break;
        default: break;
    }
    if(/-/.test(_type)) return null;
    return (
        ssrDone && isMobile ? 
        <Lazyload
        throttle={200}
        height={300}
        offset={500}
        >
            {this.mobileIframeCovid(_type)}
        </Lazyload> :
        <div>
            <Hidden only={['md', 'lg', 'xl']}>
                <Lazyload
                throttle={200}
                height={300}
                offset={500}
                once
                >
                    {this.mobileIframeCovid(_type)}
                </Lazyload>
            </Hidden>
            <Hidden smDown>
                <Lazyload
                throttle={200}
                height={300}
                offset={600}
                once
                >            
                    {this.desktopIframeCovid(_type, width)}
                </Lazyload>
            </Hidden>
        </div>
    );
  }

  componentDidMount() {
    // console.log("componentDidMount:convidWidget"+this.props.type)
  }

}

export default CovidGlobalStats;
