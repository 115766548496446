import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({ 
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: 20,
        [theme.breakpoints.up('sm')]: {
          margin: '50px 0px'
        },
      },
})

class ThereIsNoArticle extends Component {
  render() {
    const { classes } = this.props;
    return (
        <div>
            <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
                <Grid item xs={12} sm={4} md={4}>
                    <Paper className={classes.paper}>
                        <Typography component="h2" variant="h6" gutterBottom>
                            There are no articles.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ThereIsNoArticle);
