import React, { Component } from "react";
import { bindActionCreators } from "redux";
import withRedux from "next-redux-wrapper";
import withReduxSaga from "next-redux-saga";
import Head from "next/head";
import PropTypes from "prop-types";

import { fetchCategory, fetchAds, fetchCategoryVideos } from "store/posts/actions";
import Store from "store";
import { Link, Router } from "routes";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import NProgress from "components/NProgress";
import { withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Axios from "/plugins/axios";
import Grid from '@material-ui/core/Grid';
// import SubCategoryTabs from '/components/subCategoryTabs';

import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import Button from '@material-ui/core/Button';
import Headroom from "react-headroom";
import TagManager from 'react-gtm-module';
import { clearInnityAds } from '../utils/innity';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import MobileFeatureCarousel from '/components/Mobile/MobileFeatureCarousel';
import DesktopFeatureCarousel from '/components/Desktop/DesktopFeatureCarousel';
import MobileNewsItem from "/components/Mobile/MobileNewsItem";
import DesktopNewsItem from "/components/Desktop/DesktopNewsItem";
import DesktopFeatureCarouselItem from '/components/Desktop/DesktopFeatureCarouselItem';
import ThereIsNoArticle from "/components/thereIsNoArticle";
import getAdSlotByDevicePosition from "../utils/ads";
import ContentTitle from "/components/contentTitle";
import SeoMeta from '/components/seoMeta';
import {c} from '/utils/log';
import { DesktopVideoCardsSection, MobileVideoCardsSection } from '/components/CardsSection/videoCardsSection';
import CovidGlobalStats from '/components/covidGlobalStats';
import {getFooterTopOffset, infiniteHandleScroll} from '/components/NextMore/infiniteHandleScroll';
import {CustomScript} from 'customHooks/importScript';
import CustomPage from '/components/customPage';
import { findCategory, covidSections, duwunCoverLogo } from '/utils/common';

const tagManagerArgs = {
  // gtmId: 'GTM-WCJSTPT',
  dataLayer: {
    userId: '001',
    userProject: 'project',
    Section: 'Section'
  },
  // dataLayerName: 'XXX'
}

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    noArticleRoot: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },

    button: {
        margin: '13px 0px 23px',
        paddingLeft: 25,
        letterSpacing: '1px'
    },

    noArticle: {
      textAlign: 'center',
      padding: '50px 0px 80px',
      color: '#0A0A0A'
    },
  });
const getNewCount = count => count+1;


class Category extends Component {
  constructor(props){
      super(props);
      this.state = {
          newsItems: [],
          loading: false,
          next: this.props.links && this.props.links.next || null,
          currentPageNumber: 1,
          nextPageNumber: 2,
          url: this.props.url,
          count: 1,
          ssrDone: false,
          adMobileTop:{
              id: 'div-gpt-ad-mobile-top',
              networkId: null,
              adUnit: null,
              adSizes: [[320, 100]],
              mobile: true,
              position: 'TOP',
              targetingArguments: null,
          },
          adMobileMiddle:{
              id: 'div-gpt-ad-mobile-middle',
              networkId: null,
              adUnit: null,
              adSizes: [[300, 250]],
              mobile: true,
              position: 'MIDDLE',
              targetingArguments: null,
          },
          adMobileBottom:{
              id: 'div-gpt-ad-mobile-bottom',
              networkId: null,
              adUnit: null,
              adSizes: [[300, 250]],
              mobile: true,
              position: 'BOTTOM',
              targetingArguments: null,
          },
          adMobileBottomRotate:{
              id: 'div-gpt-ad-mobile-bottom-rotate',
              networkId: null,
              adUnit: null,
              adSizes: [[300, 250]],
              mobile: true,
              position: 'ROTATE',
              targetingArguments: null,
          },
          adDesktopTop:{
              id: 'div-gpt-ad-desktop-global',
              networkId: null,
              adUnit: null,
              adSizes: [[970, 250]],
              mobile: false,
              position: 'TOP',
              targetingArguments: null,
          },
          adDesktopMiddle:{
              id: 'div-gpt-ad-desktop-middle',
              networkId: null,
              adUnit: null,
              adSizes: [[970, 250]],
              mobile: false,
              position: 'MIDDLE',
              targetingArguments: null,
          },
          adDesktopBottom:{
              id: 'div-gpt-ad-desktop-bottom',
              networkId: null,
              adUnit: null,
              adSizes: [[970, 250]],
              mobile: false,
              position: 'BOTTOM',
              targetingArguments: null,
          },
          adDesktopBottomRotate:{
              id: 'div-gpt-ad-desktop-bottom-rotate',
              networkId: null,
              adUnit: null,
              adSizes: [[970, 250]],
              mobile: false,
              position: 'ROTATE',
              targetingArguments: null,
          }
      }
      this.handleScroll = infiniteHandleScroll.bind(this, this);
      // this.handleScroll = this.handleScroll.bind(this);
      this.activeCategory = this.activeCategory.bind(this);
  }

  static async getInitialProps({ query, asPath, store, isServer, res }) {
    const catQuery = query.subCategory || query.category;
    const currentDateTime = new Date().toISOString();

    // const pageCategory = store.categoriesStore && store.categoriesStore.filter((item) => item.type == 'PAGE')


    // if(pageCategory) {
    if(
      catQuery == covidSections.covid19Stats || catQuery == covidSections.covid19Map
      || catQuery == covidSections.covid19Charts
      || catQuery == covidSections.covid19MOHS
      || catQuery == covidSections.covid19JHU
      || catQuery == covidSections.newCovid19Stats
      || catQuery == covidSections.newCovid19Map
      || catQuery == covidSections.newCovid19Charts
      || catQuery == covidSections.newCovid19MOHS
      || catQuery == covidSections.newCovid19JHU
      ) { //customPage category.type == "PAGE"
        let allCategories = await Axios.get('/categories').then(function (response) {
            return response.data.data
          }).catch(function (error) {
            c.log('Allcategories Axios error', error);
          })
      return { catQuery, currentDateTime, asPath, isServer, allCategories};
    }

    await store.execSagaTasks(isServer, dispatch => {
      dispatch(fetchCategory(catQuery, currentDateTime));
      dispatch(fetchCategoryVideos(catQuery, currentDateTime, 'VIDEO'));
    });
    // await store.execSagaTasks(isServer, dispatch => {
    //   dispatch(fetchCategoryVideos(catQuery, currentDateTime, 'VIDEO'));
    // });
    if(!store.getState().posts.categoryVideos) {
      res.statusCode = store.getState().posts.error ? store.getState().posts.error.status : 404;
      // return { id };
    }
    // if(!store.getState().posts.currentCategory) {
    //   res.statusCode = store.getState().posts.error.status || 404;

    // }
    const currentCategory = store.getState().posts.currentCategory ? store.getState().posts.currentCategory : null;
    const isSub404 = !currentCategory ||
    (currentCategory &&
      (!currentCategory.category ||
        (currentCategory.category &&
          (currentCategory.category.path != ('/' + query.category + '/' + query.subCategory ) ||
          currentCategory.category.sub_categories)
        )
      )
    );
    if(query.subCategory && isSub404 && res) {
      res.statusCode = store.getState().posts.error ? store.getState().posts.error.status : '404';
    }

    return { catQuery, currentDateTime, asPath, isServer};
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const prevStateCatQuery = prevState.url.query.subCategory ? prevState.url.query.subCategory : prevState.url.query.category;
    const nextPropsCatQuery = nextProps.url.query.subCategory ? nextProps.url.query.subCategory : nextProps.url.query.category;
    c.log('category.js:getDerivedStateFromProps: ', prevStateCatQuery, ' vs ', nextPropsCatQuery);
    if(prevStateCatQuery !== nextPropsCatQuery){
        return ({
            next: `${nextProps.links && nextProps.links.next}`,
            url: nextProps.url,
            currentPageNumber: 1,
            newsItems:[], // next item remove from prev route when route change
            // next: `/categories/${ nextProps.url.query.category }?page=2`,// when next is null, next_btn disappear. But when route change, next is [1,2,3,4,5], next_btn still disappear.So this way has been resolved for this error.
        })
    }
    return {};

  }

  nextPage = (url) => {
    const nextPerPage = isMobile ? 6 : 8
    const newsItems = [];
    url = `/categories/${this.props.catQuery}?published_date=${this.props.currentDateTime}&perPage=${nextPerPage}&exclude=body${  url.split('?').length > 0 ? `&${  url.split('?')[1]}` : ''}`;
    c.log(`nextURL: ${url}`);
    c.log("nextPage:this.setState({loading:true})");
    this.setState({
        loading: true,
     });
     Axios.get(url)
         .then(res => {
             res.data.data.map((value) => newsItems.push(value));
             c.log("nextPage:Axios:success:this.setState({newsItems:...})");
             const nextURL = res.data.links.next;
             const nextPageNumber = nextURL ? (nextURL.split('page=').length > 0 ? parseInt(nextURL.split('page=')[1]) : 2) : this.state.nextPageNumber;
             this.setState({
                newsItems: [...this.state.newsItems, ...newsItems],
                next: res.data.links.next,
                // url: this.props.url,
                loading: false,
                currentPageNumber: nextPageNumber - 1,
                nextPageNumber: nextPageNumber,
                count: getNewCount(this.state.count),
                // dfpAds: updateDfpAds(this.state.dfpAds, getNewItem(getNewCount(this.state.count)))
             })
         }).catch((error) => {
           c.log("Axios Error!");
           // Error 😨
           if (error.response) {
               /*
                * The request was made and the server responded with a
                * status code that falls out of the range of 2xx
                */
               c.log(error.response.data);
               c.log(error.response.status);
               c.log(error.response.headers);
           } else if (error.request) {
               /*
                * The request was made but no response was received, `error.request`
                * is an instance of XMLHttpRequest in the browser and an instance
                * of http.ClientRequest in Node.js
                */
               c.log(error.request);
           } else {
               // Something happened in setting up the request and triggered an Error
               c.log('Error', error.message);
           }
           c.log(error.config);
           c.log("nextPage:Axios:error:this.setState({loading:false})");
           this.setState({
             loading: false
           });
       });
  }

  __nextBtn () {
    const {classes, uniReadable} = this.props;
    if(this.state.next != null) {
      return (
        <div className="next_more_btn_gtm" style={{textAlign: 'center'}}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{boxShadow: 'none', fontWeight: 'bold', borderRadius: 50}}
            onClick={() => !this.state.loading ? this.nextPage(this.state.next) : null}
          >
            <span>
              {
                  uniReadable ? 'နောက်ထပ်':  'ေနာက္ထပ္'
              }
            </span>
            {this.state.loading ?
              <div className="ld ld-ring ld-spin" style={{marginLeft: 5, marginRight: 5}} />
                  :
              <div style={{display: 'inline-flex'}}>
                <Icon>keyboard_arrow_down</Icon>
              </div>
                }
          </Button>

        </div>
      )
    }
  }

  __getDfpAds = (index) => {
    if(index > 5) return null; // return no ad when page > 5
    const { ads } = this.props;
    const adMobileBottomRotate = ads && getAdSlotByDevicePosition(true, ads, this.state.adMobileBottomRotate.position);
    const adDesktopBottom = ads && getAdSlotByDevicePosition(false, ads, this.state.adDesktopBottom.position);
    const adDesktopBottomRotate = ads && getAdSlotByDevicePosition(false, ads, this.state.adDesktopBottomRotate.position);
    const ssrDone = this.state.ssrDone;
    const { categoryObj } = this.props;

    return(
      <div style={{ display: categoryObj.no_ads && categoryObj.no_ads == true ? 'none' : ''}} className='ads-style'>
        <Hidden only={['md', 'lg', 'xl']}>
          {/* <MobileView> */}
          {
            ssrDone && adMobileBottomRotate.id ?
            <AdSlot
              sizes={adMobileBottomRotate.adSizes}
              dfpNetworkId={adMobileBottomRotate.networkId}
              adUnit={adMobileBottomRotate.adUnit}
              // targetingArguments={adMobileBottomRotate.targetingArguments}
              shouldRefresh={()=> true}
              slotId={this.state.adMobileBottomRotate.id + "-" + index}
            /> : null
          }
          {/* </MobileView> */}
        </Hidden>
        <Hidden smDown>
          {/* <BrowserView> */}
          {
            (index == 0) ?
              ssrDone && adDesktopBottom.id ?
              <AdSlot
                  sizes={adDesktopBottom.adSizes}
                  dfpNetworkId={adDesktopBottom.networkId}
                  adUnit={adDesktopBottom.adUnit}
                  shouldRefresh={() => true}
                  slotId={this.state.adDesktopBottom.id}
              /> : null
              :
              ssrDone && adDesktopBottomRotate.id ?
              <AdSlot
                  sizes={adDesktopBottomRotate.adSizes}
                  dfpNetworkId={adDesktopBottomRotate.networkId}
                  adUnit={adDesktopBottomRotate.adUnit}
                  shouldRefresh={() => true}
                  slotId={this.state.adDesktopBottomRotate.id + "-" + index}
                  // slotId={adDesktopBottomRotate.id}
              /> : null
          }
          {/* </BrowserView> */}
        </Hidden>
      </div>
    )
  }

  activeCategory = () => {
    const { allCategories, url, categoryObj, catQuery } = this.props;
    const categorySegment = url.query.category;
    let parentCatObj = null;
    const isBrandSite = url.asPath.includes('/sites/');
    const isBrandCategory = false;
    let findCurrentCategory = allCategories && allCategories.length > 0 && findCategory(allCategories, catQuery)
    parentCatObj = categoryObj ? categoryObj : findCurrentCategory && findCurrentCategory.currentCategoryObj; //for custom category page and category page(categoryObj is null in custom page)
    let subCategoryObj = null;
    if(url.query.subCategory) {
      subCategoryObj = parentCatObj && parentCatObj.sub_categories && parentCatObj.sub_categories.find(item => item.slug == url.query.subCategory);
    }
    if(!parentCatObj && !subCategoryObj && isBrandSite) {
        isBrandCategory = true;
        parentCatObj = categoryObj; // Brand Categories are excluded in api/categories and categoriesStore
    }
    let categoryType = subCategoryObj ? `${subCategoryObj.type}` : `${parentCatObj && parentCatObj.type}`;
    let categoryName = subCategoryObj ? `${subCategoryObj.name}` : `${parentCatObj && parentCatObj.name}`;
    let categoryColor = subCategoryObj ? `${subCategoryObj.color}` : `${parentCatObj && parentCatObj.color}`;
    let categorySlug = subCategoryObj ? `${subCategoryObj.slug}` : `${parentCatObj && parentCatObj.slug}`;
    let categoryCover = subCategoryObj ? (subCategoryObj.cover && `${subCategoryObj.cover.original}`) : (parentCatObj && parentCatObj.cover && `${parentCatObj.cover.original}`);
    return ({
        categoryType,
        categoryName,
        categoryColor,
        categorySlug,
        categoryCover,
        isBrandCategory
    })
}

  render() {
    // console.log("#######category.js::render()######");
    const {links, videos, classes, isServer, ads, sizes, carousel, categoryPostItems, catIndex, categoryObj, categoriesStore, metaTag } = this.props;
    const ssrDone = this.state.ssrDone;
    let activeCategory = this.activeCategory();
    const categoryName = activeCategory.categoryName
    const categoryType = activeCategory.categoryType
    const categoryColor = activeCategory.categoryColor
    const categorySlug = activeCategory.categorySlug
    const categoryCover = activeCategory.categoryCover
    const isBrandCategory = activeCategory.isBrandCategory
    const defaultImage = duwunCoverLogo;
    let brandCategoryCover = isBrandCategory ? (categoryObj && categoryObj.cover && `${categoryObj.cover.social}`) : defaultImage;
    let ogImage = isBrandCategory ? brandCategoryCover : categoryCover || defaultImage;

    const viewAllCOVIDStats = (subSection, label) => (<Grid item xs={12}>
      <Link route={"subCategory"} params={{ category: covidSections.covid19, subCategory: subSection }}>
        <a className="anchor-link">
          <div className="read_all_articles">
            <Typography
              variant="body1"
              align="right"
              style={{
              color: categoryColor,
              cursor: 'pointer',
              textTransform: 'capitalize',
              marginBottom: 26
              }}
            >
              {label}
              <Icon style={{ paddingTop: 6 }}>
              keyboard_arrow_right
              </Icon>
            </Typography>
          </div>
        </a>
      </Link>
    </Grid>)

    if(categoryType == 'PAGE'){ // Is Custom Page
      return (
        <CustomPage props={this.props} ssrDone={ssrDone}></CustomPage>
      )
    }

    const {newsItems} =this.state;
    const adMobileTop = ads && getAdSlotByDevicePosition(true, ads, this.state.adMobileTop.position);
    const adMobileMiddle = ads && getAdSlotByDevicePosition(true, ads, this.state.adMobileMiddle.position);
    const adMobileBottom = ads && getAdSlotByDevicePosition(true, ads, this.state.adMobileBottom.position);
    const adDesktopTop = ads && getAdSlotByDevicePosition(false, ads, this.state.adDesktopTop.position);
    const adDesktopMiddle = ads && getAdSlotByDevicePosition(false, ads, this.state.adDesktopMiddle.position);
    const adDesktopBottom = ads && getAdSlotByDevicePosition(false, ads, this.state.adDesktopBottom.position);

    const isElectionSection = categorySlug.includes("election") || categorySlug.includes("ge2020");
    const isCovidSection = categorySlug.includes("covid-19");
    const isCovidNewsSection = categorySlug == covidSections.covid19Local || categorySlug == covidSections.covid19Global;
    const showVideoSection = false;
    function getCovidWidgetType() {
      switch (categorySlug)
      {
          case covidSections.covid19:
          case covidSections.covid19Global:
            return "all";
          case covidSections.covid19Local:
            return "mohs";
          default:
            return "all";
      };
    }

    const covidWidget = (
      isCovidSection &&
      <div style={{maxWidth:'1140px', margin: '0 auto'}}>
        {/* <Grid container>
        <Grid item xs={12}>  */}

        <CovidGlobalStats ssrDone={ssrDone} type={getCovidWidgetType()} />
        {viewAllCOVIDStats(covidSections.covid19Stats, 'View More Detailed COVID-19 Stats')}
        {/* </Grid>
        </Grid> */}
      </div>
    );
    return (
      <div>
        <Head>
          <title>{metaTag && metaTag.title ? metaTag.title : categoryName}</title>
        </Head>
        {/* Lupon Media */}
        {/* <CustomScript src="https://adxbid.info/duwun.js" /> */}
        <SeoMeta
            url={`${process.env.BACKEND_URL}${this.props.asPath}`}
            type="article"
            title={metaTag && metaTag.title ? metaTag.title : categoryName}
            description={metaTag && metaTag.description}
            image={ogImage}
            themeColor={categoryColor}
            section={metaTag && metaTag.section ? metaTag.section : categoryName}
            keywords={metaTag && metaTag.keywords ? metaTag.keywords : categoryName}
          />
        <NProgress />
        {!isCovidSection &&
        <div style={{ display: categoryObj.no_ads && categoryObj.no_ads == true ? 'none' : ''}} className="ads-global-style">
          <Hidden only={['md', 'lg', 'xl']}>
            {/* <MobileView> */}
            <div style={{textAlign : 'center'}}>
            {
              ssrDone && adMobileTop.id &&
              <AdSlot
                sizes={adMobileTop.adSizes}
                dfpNetworkId={adMobileTop.networkId}
                adUnit={adMobileTop.adUnit}
                shouldRefresh={()=> true}
                slotId={this.state.adMobileTop.id}
              />
            }
            </div>
            {/* </MobileView> */}
          </Hidden>
          <Hidden smDown>
            {/* <BrowserView> */}
            <div style={{textAlign : 'center'}}>
              {
                ssrDone && adDesktopTop.id &&
                <AdSlot
                  sizes={adDesktopTop.adSizes}
                  dfpNetworkId={adDesktopTop.networkId}
                  adUnit={adDesktopTop.adUnit}
                  // targetingArguments={adDesktopTop.targetingArguments}
                  shouldRefresh={()=> true}
                  slotId={this.state.adDesktopTop.id}
                />
              }
            </div>
            {/* </BrowserView> */}
          </Hidden>
        </div>
        }
        {/* <div className={classes.noArticleRoot}> */}
        {categoryPostItems && categoryPostItems.length == 0 ?
          <ThereIsNoArticle /> :
          <div>
            <Hidden only={['md', 'lg', 'xl']}>
              {/* <MobileView> */}
              {/** ************* mobile carousel ******************* */}
              { carousel && (
                carousel.length == 0 ? null :
                carousel.length == 1 ?  <DesktopFeatureCarouselItem carouselItem={carousel} />  :
                <MobileFeatureCarousel carouselItems={carousel} />
                )
              }
              {/** ************* end mobile carousel ******************* */}
              <div className={classes.root}>
                <div className='mobileLayout'>
                  <ContentTitle
                    contentTitle={`${categoryName + (isCovidNewsSection ? " News" : "")}`}
                    color={categoryColor}
                    typoComponent="h1"
                  />
               
                  {categoryPostItems && categoryPostItems.length > 0 && categoryPostItems.map((item, index) => (
                      index < 6 ?
                        <React.Fragment key={index}>
                        
                            <MobileNewsItem key={index} item={item} />
                          
                          {index === 2 ?
                          <>
                            <div className='onesignal-customlink-container' style={{ paddingBottom: 10 }}></div>

                            {showVideoSection ? videos && videos.length > 0 &&
                            <div style={{margin: '0px -16px 10px -16px'}}>
                              <MobileVideoCardsSection
                                contentTitle={`Latest ${categoryName} Videos`}
                                color='#fff'
                                routeName='categoryVideos'
                                paramsName={{ id: categorySlug }}
                                readAll={`View All Latest ${categoryName} Videos`}
                                newsItems={null}
                                items={videos}
                                maxNum='3'
                              />
                            </div>
                            : null
                            }
                            { isCovidSection ? covidWidget :
                            <div style={{ display: categoryObj.no_ads && categoryObj.no_ads == true ? 'none' : ''}} className="ads-style">
                            {
                              ssrDone && adMobileMiddle.id &&
                              <AdSlot
                                sizes={adMobileMiddle.adSizes}
                                dfpNetworkId={adMobileMiddle.networkId}
                                adUnit={adMobileMiddle.adUnit}
                                // targetingArguments={adMobileMiddle.targetingArguments}
                                shouldRefresh={()=> true}
                                slotId={this.state.adMobileMiddle.id}
                              />
                            }
                            </div>
                            }
                          
                          </>
                          : null
                          }
                        
                          {index === 5 ? (isCovidSection ?

                            adMobileBottom.id &&
                            <div style={{ textAlign : 'center' }}>
                            <AdSlot
                                sizes={adMobileBottom.adSizes}
                                dfpNetworkId={adMobileBottom.networkId}
                                adUnit={"duwun_desktop_new_template_global_rectangle_300x250"}
                                // targetingArguments={adMobileBottom.targetingArguments}
                                shouldRefresh={()=> true}
                                slotId={this.state.adMobileBottom.id}
                            /><br />
                            </div>
                            :
                            <div style={{ display: categoryObj.no_ads && categoryObj.no_ads == true ? 'none' : ''}} className='ads-style'>
                            {
                              ssrDone && adMobileBottom.id &&
                              <AdSlot
                                sizes={adMobileBottom.adSizes}
                                dfpNetworkId={adMobileBottom.networkId}
                                adUnit={adMobileBottom.adUnit}
                                // targetingArguments={adMobileBottom.targetingArguments}
                                shouldRefresh={()=> true}
                                slotId={this.state.adMobileBottom.id}
                              />
                            }
                            </div>)
                            : null
                          }
                       
                        </React.Fragment>
                        : null
                      )
                    )
                  }
                  
                
                  {newsItems && newsItems.map((newsItem, index) => (
                    <React.Fragment key={index}>
                      <MobileNewsItem key={index} item={newsItem} />
                      {index > 0 && ((index+1) % 3) === 0 ? this.__getDfpAds(Math.floor(index / 3)) : null }
                    </React.Fragment>
                    )
                  )}
                  { links.next ?  this.__nextBtn() : null}

                  </div>
              </div>
              {/* </MobileView> */}
            </Hidden>
            <Hidden smDown>
              {/* <BrowserView> */}
              <div className={classes.root}>
                <div className='layout'>
                  {/* ****************** desktop feature carousel **************** */}
                  { carousel &&
                  <div style={{ display: 'block' }}>
                    { carousel.length == 0 ? null : carousel.length == 1 ?  <DesktopFeatureCarouselItem carouselItem={carousel} /> : <DesktopFeatureCarousel carouselItems={carousel} /> }
                  </div>
                  }
                  {/* ****************** category Article **************** */}
                  <Grid container>
                    <ContentTitle
                      contentTitle={`${categoryName + (categorySlug == covidSections.covid19Local || categorySlug == covidSections.covid19Global ? " News" : "")}`}
                      color={categoryColor}
                      typoComponent="h1"
                    />
                    {categoryPostItems && categoryPostItems.length > 0 && categoryPostItems.map((item, index) => (
                      <React.Fragment key={index}>
                          <Grid item xs={3}>
                            <DesktopNewsItem item={item}  />
                          </Grid>
                          {index === 3 &&
                            <>
                              <div className='onesignal-customlink-container' style={{ padding: 10 }}></div>

                              <Grid item xs={12} key={index}>
                              { showVideoSection ? videos && videos.length > 0 &&
                                <DesktopVideoCardsSection
                                  contentTitle={`Latest ${categoryName} Videos`}
                                  color='#fff'
                                  routeName='categoryVideos'
                                  paramsName={{ id: categorySlug }}
                                  readAll={`View All Latest ${categoryName} Videos`}
                                  newsItems={null}
                                  items={videos}
                                  maxNum='4'
                                /> : null
                              }
                              </Grid>
                            </>
                           }
                          {index === 7 ?
                            <Grid item xs={12} key={index}>
                              { isCovidSection ? covidWidget :
                              <div style={{ display: categoryObj.no_ads && categoryObj.no_ads == true ? 'none' : ''}} className="ads-style">
                                {
                                    ssrDone && adDesktopMiddle.id &&
                                    <AdSlot
                                      sizes={adDesktopMiddle.adSizes}
                                      dfpNetworkId={adDesktopMiddle.networkId}
                                      adUnit={adDesktopMiddle.adUnit}
                                      // targetingArguments={adDesktopMiddle.targetingArguments}
                                      shouldRefresh={()=> true}
                                      slotId={this.state.adDesktopMiddle.id}
                                    />
                                  }
                              </div>
                              }
                            </Grid>
                               : null}
                        </React.Fragment>
                      ))}
                  </Grid>
                  <Grid container>
                    {newsItems && newsItems.map((item, index) => (
                      <React.Fragment key={index}>
                          <Grid item xs={3}>
                            <DesktopNewsItem item={item}  />
                          </Grid>
                          {index > 0 && ((index+1) % 8) === 0 ? <Grid item xs={12} key={index}> {this.__getDfpAds(Math.floor(index / 8))} </Grid> : null}
                        </React.Fragment>
                                  ))}
                  </Grid>

                  { links.next ?  this.__nextBtn() : null}
                </div>
              </div>
              {/* </BrowserView> */}
            </Hidden>
          </div>
          }
        {/* </div> */}
      </div>
    );
  }
    componentDidMount () {
        c.log("category.js::componentDidMount()");
        window.noGlobalAds = this.props.categoryObj && this.props.categoryObj.no_global_ads;

        // if(isMobile)
            // this.state.categoryPostItems.splice(-2, 2)
        c.log("category.js:componentDidMount:this.setState({ssrDone:true})");
        window.DFPManagerLoaded = false;
        this.setState({
            ssrDone: true,
            currentPageNumber: 1
            // next: this.props.links.next,
        });
        // if(isMobile || isBrowser) {
          this.previousFooterTopOffset = getFooterTopOffset();
          c.log("category.js:componentDidMount:previousFooterTopOffset");
          c.log(this.previousFooterTopOffset, "vs", this.newFooterTopOffset);
          window.addEventListener('scroll', this.handleScroll);
        // }
    }

    componentWillUnmount() {
      c.log("category.js::componentWillUnmount()");
      window.removeEventListener('scroll', this.handleScroll);
      if (typeof localStorage !== "undefined") {
        localStorage.setItem('category', null);
      }      
    }

    // handleScroll(event) {
    //   // c.log("handleScroll > event:");
    //   // c.log(event);
    //   // c.log("handleScroll > that:");
    //   // c.log(that);

    //   if(window.pageYOffset > 200 && !this.pageFiveLoaded) {
    //     if(this.state.next != null && !this.state.loading) {
    //       this.nextPage(this.state.next);
    //     }
    //     let nextApi = this.state.next;
    //     let endInfiniteScroll = nextApi != null && nextApi.includes("page=5");
    //     if(endInfiniteScroll){
    //       this.pageFiveLoaded = true;
    //     }
    //   }
    // }

    componentDidUpdate(previousProps, previousState){
        c.log("category.js::componentDidUpdate()");
        const categorySegment = this.props.url.query.category;
        const subCategorySegment = this.props.url.query.subCategory;
        // if(!this.state.loading) {

          DFPManager.setTargetingArguments({'section': categorySegment, 'sub-section': subCategorySegment });

          DFPManager.configureDisableInitialLoad(true);
          // ref: https://developers.google.com/publisher-tag/guides/control-ad-loading
          DFPManager.setCollapseEmptyDivs(true);

          // DFPManager.reload();

          if(!window.DFPManagerLoaded)
            DFPManager.load();
          else {
            // DFPManager.reload();
          }
          //DFPManager.refresh();

          window.DFPManagerLoaded = true;
        // }

        if(isBrowser) {
          switch (this.state.currentPageNumber)
          {
            case 1:
              DFPManager.refresh([this.state.adDesktopTop.id]);
              break;
            case 2:
              DFPManager.refresh([this.state.adDesktopMiddle.id]);
              break;
            case 3:
              DFPManager.refresh([this.state.adDesktopBottom.id]);
              break;
            default:
              DFPManager.refresh([this.state.adDesktopBottomRotate.id + "-" + (this.state.currentPageNumber-3)]);
              break;
          };
        }
        else if (isMobile) {
          switch (this.state.currentPageNumber)
          {
            case 1:
              DFPManager.refresh([this.state.adMobileTop.id]);
              DFPManager.refresh([this.state.adMobileMiddle.id]);
              DFPManager.refresh([this.state.adMobileBottom.id]);
              break;
            default:
              let id1 = this.state.currentPageNumber % 2 == 0 ? this.state.currentPageNumber - 2 : this.state.currentPageNumber - 1;
              let id2 = this.state.currentPageNumber % 2 == 0 ? this.state.currentPageNumber - 1 : this.state.currentPageNumber;
              console.log("refreshing adMobileBottomRotate:", id1);
              DFPManager.refresh([this.state.adMobileBottomRotate.id + "-" + id1]);
              console.log("refreshing adMobileBottomRotate:", id2);
              DFPManager.refresh([this.state.adMobileBottomRotate.id + "-" + id2]);
              break;
          };
        }

        tagManagerArgs.dataLayer.Section = categorySegment;

        TagManager.dataLayer(tagManagerArgs);
        if (typeof localStorage !== "undefined") {
          localStorage.setItem('category', categorySegment);
        }

        clearInnityAds();
        const previousPropsCatQuery = previousProps.url.query.subCategory || previousProps.url.query.category;
        const propsCatQuery = this.props.url.query.subCategory || this.props.url.query.category;

        // let nextPage = this.state.next != null && this.state.next.split('?').length > 0 ? this.state.next.split('?')[1] : '';
        this.nextPageNumber = this.state.nextPageNumber;//nextPage.split('page=').length > 0 ? parseInt(nextPage.split('page=')[1]) : 2;
        if(!this.currentPageNumber) {
          this.currentPageNumber = 1;
        }
        c.log("category.js:previousPropsCatQuery:", previousPropsCatQuery, " vs propsCatQuery:", propsCatQuery);

        if(previousPropsCatQuery != propsCatQuery) {
          this.initialPageLoaded = false;
          this.nextPageNumber = 2;
        }

        {/* ***** showMore and infiniteScroll Events ***** */}
        if(!this.state.loading && this.initialPageLoaded) {
          let activeCategory = this.activeCategory();
          const categoryName = activeCategory.categoryName;
          const pageUrl = document.location.origin + document.location.pathname;
          const showMorePagePath = pageUrl + "#page-" + this.state.currentPageNumber;
          if(this.state.currentPageNumber > 1 && this.currentPageNumber != this.state.currentPageNumber) {
            window.parent.location.hash = "page-" + this.state.currentPageNumber;
            c.log("category.js:window.dataLayer.push:showMoreEvent:", showMorePagePath);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'showMoreEvent',
                showMoreEventPage: showMorePagePath,
                showMoreEventTitle: categoryName ? categoryName : 'No Title'
            });
            this.currentPageNumber = this.state.currentPageNumber;
          }
        }

        this.newFooterTopOffset = getFooterTopOffset();
        c.log("category.js:componentDidUpdate:newFooterTopOffset");
        c.log(this.previousFooterTopOffset, "vs", this.newFooterTopOffset);
    }
}

Category.propTypes = {
    classes: PropTypes.object.isRequired,
    // categoryPostItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    // videos: PropTypes.arrayOf(PropTypes.object).isRequired,
    // tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        fetchCategory,
        fetchCategoryVideos
        // fetchTags
      },
      dispatch
    );

  const mapStateToProps = state => ({
    categoryPostItems: state.posts.currentCategory && state.posts.currentCategory.data || null,
    // tags: state.posts.tags,
    links: state.posts.currentCategory && state.posts.currentCategory.links || null,
    ads: state.posts.currentCategory && state.posts.currentCategory.ads || null,
    carousel: state.posts.currentCategory && state.posts.currentCategory.other.carousel || null,
    categoryObj: state.posts.currentCategory && state.posts.currentCategory.category || null,
    videos: state.posts.categoryVideos && state.posts.categoryVideos.data || null,
    metaTag: state.posts.currentCategory && state.posts.currentCategory.metaTag || null
  });

  export default withRedux(Store, mapStateToProps, mapDispatchToProps)(
    withReduxSaga(withStyles(styles)(Category))
  );
